import React, {useState} from 'react'
import * as Yup from 'yup'
import {Link} from 'react-router-dom'
import {useFormik} from 'formik'
import {useDispatch} from 'react-redux'
import {forgotPassword} from 'redux/actions/auth-action'
import {Action, ThunkDispatch} from '@reduxjs/toolkit'
import {useIntl} from 'react-intl'
import {Input} from '_metronic/partials/input/Input'
import {SubmitButton} from '../../../../_metronic/partials/submitButton/SubmitButton'

export function ForgotPassword() {
  const intl = useIntl()
  const dispatch = useDispatch<ThunkDispatch<any, any, Action>>()
  const [email, setEmail] = useState<string>('')

  const forgotPasswordSchema = Yup.object().shape({
    email: Yup.string()
      .email(
        intl.formatMessage({id: 'VALIDATIONS.INVALID'}, {field: intl.formatMessage({id: 'EMAIL'})})
      )
      .min(3, intl.formatMessage({id: 'VALIDATIONS.MIN_CHARACTERS'}, {count: 3}))
      .max(50, intl.formatMessage({id: 'VALIDATIONS.MAX_CHARACTERS'}, {count: 50}))
      .required(
        intl.formatMessage(
          {id: 'VALIDATIONS.IS_REQUIRED'},
          {field: intl.formatMessage({id: 'EMAIL'})}
        )
      ),
  })

  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
  const formik = useFormik({
    initialValues: {email},
    validationSchema: forgotPasswordSchema,
    onSubmit: async (values, {setStatus, setSubmitting, resetForm}) => {
      setSubmitting(true)

      await dispatch(forgotPassword(values.email))
        .then(() => {
          setHasErrors(false)
          resetForm()
        })
        .catch((e) => {
          setHasErrors(true)
          setStatus(e.message)
        })
      setSubmitting(false)
    },
  })

  return (
    <form
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      noValidate
      id='kt_login_password_reset_form'
      onSubmit={formik.handleSubmit}
    >
      <div className='text-center mb-10'>
        {/* begin::Title */}
        <h1 className='text-dark mb-3'>{intl.formatMessage({id: 'AUTH.FORGOT_PASSWORD'})}</h1>
        {/* end::Title */}

        {/* begin::Link */}
        <div className='text-gray-400 fw-bold fs-4'>
          {intl.formatMessage({id: 'AUTH.FORGOT_PASSWORD.SUB_TITLE'})}
        </div>
        {/* end::Link */}
      </div>

      {/* begin::Title */}
      {hasErrors === true && (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>
            {intl.formatMessage({id: 'AUTH.FORGOT_PASSWORD.ERRORS'})}
          </div>
        </div>
      )}

      {hasErrors === false && (
        <div className='mb-10 bg-light-warning p-8 rounded text-primary text-center'>
          <div>{intl.formatMessage({id: 'AUTH.FORGOT_PASSWORD.CHECK_EMAIL.L1'})}</div>
          <div>{intl.formatMessage({id: 'AUTH.FORGOT_PASSWORD.CHECK_EMAIL.L2'})}</div>
        </div>
      )}
      {/* end::Title */}

      {/* begin::Form group */}
      <div className='fv-row mb-10'>
        <Input
          type='email'
          label={intl.formatMessage({id: 'EMAIL'})}
          placeholder={intl.formatMessage({id: 'EMAIL'})}
          touched={!!formik.touched.email}
          error={formik.errors.email || ''}
          rowInput
          {...formik.getFieldProps('email')}
        />
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
        <SubmitButton
          label={intl.formatMessage({id: 'SUBMIT'})}
          loading={formik.isSubmitting}
          disabled={formik.isSubmitting || !formik.isValid}
        />
      </div>
      <div className='d-flex flex-wrap justify-content-center mt-10'>
        <Link to='/auth/login'>
          <div id='kt_login_password_reset_form_cancel_button'>
            {intl.formatMessage({id: 'AUTH.GOTO_LOGIN'})}
          </div>
        </Link>
      </div>

      {/* end::Form group */}
    </form>
  )
}
