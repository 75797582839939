/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {useDispatch} from 'react-redux'
import * as Yup from 'yup'
import {Link} from 'react-router-dom'
import {useFormik} from 'formik'
// import * as auth from '../redux/AuthRedux'
// import {login} from '../redux/AuthCRUD'
// import {toAbsoluteUrl} from '_metronic/helpers'
import {Input} from '_metronic/partials/input/Input'
import {SubmitButton} from '_metronic/partials/submitButton/SubmitButton'
import {Alert} from '_metronic/partials/alert/Alert'
// import {RootState} from 'redux/store'
import {login} from 'redux/actions/auth-action'
import {Action, ThunkDispatch} from '@reduxjs/toolkit'
import {ILoginDto} from 'interfaces'
import {useIntl} from 'react-intl'
import { toAbsoluteUrl } from '_metronic/helpers'

const initialValues: ILoginDto = {
  username: '',
  password: '',
  rememberMe: false,
}

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function Login() {
  const intl = useIntl()
  const dispatch = useDispatch<ThunkDispatch<any, any, Action>>()

  const loginSchema = Yup.object().shape({
    username: Yup.string()
      .email(
        intl.formatMessage({id: 'VALIDATIONS.INVALID'}, {field: intl.formatMessage({id: 'EMAIL'})})
      )
      .min(3, intl.formatMessage({id: 'VALIDATIONS.MIN_CHARACTERS'}, {count: 3}))
      .max(50, intl.formatMessage({id: 'VALIDATIONS.MAX_CHARACTERS'}, {count: 50}))
      .required(
        intl.formatMessage(
          {id: 'VALIDATIONS.IS_REQUIRED'},
          {field: intl.formatMessage({id: 'EMAIL'})}
        )
      ),
    password: Yup.string()
      .min(6, intl.formatMessage({id: 'VALIDATIONS.MIN_CHARACTERS'}, {count: 6}))
      .max(50, intl.formatMessage({id: 'VALIDATIONS.MAX_CHARACTERS'}, {count: 50}))
      .required(
        intl.formatMessage(
          {id: 'VALIDATIONS.IS_REQUIRED'},
          {field: intl.formatMessage({id: 'PASSWORD'})}
        )
      ),
    rememberMe: Yup.boolean(),
  })

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      await dispatch(login(values)).catch((error) => {
        setStatus(error.message)
        setSubmitting(false)
      })
    },
  })

  return (
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      {/* begin::Logo */}
      <div className='mb-12 col-12 d-flex flex-column justify-content-center align-items-center'>
        <img alt='Logo' src={toAbsoluteUrl('/logo.png')} className='h-150px' />
        <h2 className='mt-10 text-center lh-2rem'>{intl.formatMessage({id: 'AUTH.TITLE.L1'})}</h2>
        <h2 className='text-center lh-2rem'>{intl.formatMessage({id: 'AUTH.TITLE.L2'})}</h2>
      </div>
      {/* end::Logo */}

      <div className='row align-self-start mb-10'>
        <Input
          type='email'
          label={intl.formatMessage({id: 'EMAIL'})}
          placeholder={intl.formatMessage({id: 'EMAIL'})}
          touched={!!formik.touched.username}
          error={formik.errors.username || ''}
          rowInput
          {...formik.getFieldProps('username')}
        />
      </div>
      <div className='row align-self-start mb-10'>
        <Input
          type='password'
          label={intl.formatMessage({id: 'PASSWORD'})}
          placeholder={intl.formatMessage({id: 'PASSWORD'})}
          touched={!!formik.touched.password}
          error={formik.errors.password || ''}
          rowInput
          {...formik.getFieldProps('password')}
        />
      </div>
      <div className='col-md-9 offset-md-3 mb-10'>
        <div className='form-check'>
          <input
            id={'remember-me'}
            className='form-check-input'
            type='checkbox'
            {...formik.getFieldProps('rememberMe')}
          />
          <label htmlFor='remember-me' className='form-check-label'>
            {intl.formatMessage({id: 'AUTH.REMEMBER_ME'})}
          </label>
        </div>
      </div>
      <div className='col-md-9 offset-md-3 mb-10'>
        <div className='row flex align-items-center justify-content-center'>
          <div className='col-5 flex align-items-center justify-content-center'>
            <SubmitButton
              label={intl.formatMessage({id: 'AUTH.SIGN_IN'})}
              loading={formik.isSubmitting}
              disabled={formik.isSubmitting || !formik.isValid}
              loadingLabel={intl.formatMessage({id: 'AUTH.SIGNING_IN'})}
            />
          </div>
          <div className='col-7'>
            <Link
              to='/auth/forgot-password'
              className='link-primary fs-6 fw-bolder'
              style={{marginLeft: '5px'}}
            >
              {intl.formatMessage({id: 'AUTH.FORGOT_PASSWORD'})}
            </Link>
          </div>
        </div>
      </div>
      {/* end::Form group */}
      {formik.status && <Alert error message={formik.status} />}
    </form>
  )
}
