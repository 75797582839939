/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {useLayout} from '../core'
import {useIntl} from 'react-intl'

const Footer: FC = () => {
  const intl = useIntl()
  const {classes} = useLayout()
  return (
    <div className='footer py-4 d-flex flex-lg-column' id='kt_footer'>
      {/* begin::Container */}
      <div
        className={`${classes.footerContainer} d-flex flex-column flex-md-row align-items-center justify-content-between`}
      >
        {/* begin::Copyright */}
        {/*<div className='text-dark order-2 order-md-1'>*/}
        {/*  <a href='#' className='text-gray-800 text-hover-primary'>*/}
        {/*    {`${intl.formatMessage({id: 'FOOTER_COPYRIGHT'})} ${new Date().getFullYear()}`}*/}
        {/*  </a>*/}
        {/*</div>*/}
        {/* end::Copyright */}

        {/* begin::Nav */}
        <ul className='menu menu-gray-600 menu-hover-primary fw-bold order-1'>
          <li className='menu-item'>
            <a href='https://www.i-biljeske.hr/ugovor' className='menu-link pe-2' target="_blank" rel="noopener noreferrer">
              {intl.formatMessage({id: 'FOOTER_LINK_AGREEMENT'})}
            </a>
          </li>
          <li className='menu-item'>
            <a href='https://www.i-biljeske.hr/uvjeti' className='menu-link px-2' target="_blank" rel="noopener noreferrer">
              {intl.formatMessage({id: 'FOOTER_LINK_TERMS'})}
            </a>
          </li>
        </ul>
        {/* end::Nav */}
      </div>
      {/* end::Container */}
    </div>
  )
}

export {Footer}
