/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {Link, Redirect, Route, Switch, useHistory} from 'react-router-dom'
import {Error500} from './components/Error500'
import {Error404} from './components/Error404'
import {toAbsoluteUrl} from '_metronic/helpers'
import {useIntl} from 'react-intl'

const ErrorsPage: React.FC = () => {
  const intl = useIntl()

  const history = useHistory()
  const redirectToDashboard = () => {
    history.push('/')
  }

  return (
    <div className='d-flex flex-column flex-root'>
      <div
        className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'
        style={{backgroundImage: `url('${toAbsoluteUrl('/media/illustrations/progress-hd.png')}')`}}
      >
        <div className='d-flex flex-column flex-column-fluid text-center p-10 py-lg-20'>
          <a href='/' className='mb-5 pt-lg-10'>
            <img alt='Logo' src={toAbsoluteUrl('/logo.png')} className='h-100px mb-5' />
          </a>
          <div className='mb-10'>
            <Switch>
              <Route path='/error/404' exact={true}>
                <Error404 />
              </Route>
              <Route path='/error/500' exact={true}>
                <Error500 />
              </Route>
              <Redirect from='/error' exact={true} to='/error/404' />
              <Redirect to='/error/404' />
            </Switch>

            <div className='text-center'>
              <button className='btn btn-primary btn-sm' onClick={redirectToDashboard}>
                {intl.formatMessage({id: 'AUTH.GOTO_HOME'})}
              </button>
            </div>
          </div>
          <div
            className='
          d-flex
          flex-row-auto
          bgi-no-repeat
          bgi-position-x-center
          bgi-size-contain
          bgi-position-y-bottom
          min-h-100px min-h-lg-200px
        '
            style={{
              backgroundImage: `url('${toAbsoluteUrl('/media/illustrations/sketchy-1/17.png')}')`,
            }}
          ></div>
        </div>

        <div className='d-flex flex-center flex-column-auto p-10'>
          <ul className='menu menu-gray-600 menu-hover-primary fw-bold order-1'>
            <li className='menu-item'>
              <a href='https://www.i-biljeske.hr/ugovor' className='menu-link pe-2' target="_blank" rel="noopener noreferrer">
                {intl.formatMessage({id: 'FOOTER_LINK_AGREEMENT'})}
              </a>
            </li>
            <li className='menu-item'>
              <a href='https://www.i-biljeske.hr/uvjeti' className='menu-link px-2' target="_blank" rel="noopener noreferrer">
                {intl.formatMessage({id: 'FOOTER_LINK_TERMS'})}
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export {ErrorsPage}
