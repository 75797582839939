import { toAbsoluteUrl } from "_metronic/helpers";
import { ReactNode } from "react";

type Props = {
  active: boolean;
  children: ReactNode
}

export function FallbackOverlay({ active = false, children }: Props) {
  return (
    <>
      {children}

      {active && <div id="overlay">
        <div className="d-flex align-items-center justify-content-center flex-column h-100 w-100">
          <img src={toAbsoluteUrl('/biljeske-loading.gif')} alt="Biljeske logo" className="h-250px" />
        </div>
      </div>}
    </>

  );
}
